$corS01: #00ad4d;
$corFF: #FFF;
$corF8: #F8F8F8;
$corF4: #f4f6f9;
$corF3: #f3f5f8;
$corED: #EDEDED;
$corEC: #ecedf2;
$corDD: #DDD;
$corCC: #CCC;
$corBB: #BBB;
$corAA: #AAA;
$cor77: #777;
$cor00: #000;
$corFF00: rgba(255,255,255,0);
$corFF10: rgba(255,255,255,0.1);
$corFF50: rgba(250,250,250,0.5);
$cor0010: rgba(0,0,0,0.1);
$cor0020: rgba(0,0,0,0.2);
$cor0050: rgba(0,0,0,0.5);
$gray10: rgba(236,237,242,0.1);
$gray50: rgba(236,237,242,0.5);

$corGradMid: $corFF;
$corGradEnd: $corFF10;
$corGradStart: $corFF10;

$bgBody         : $corEC;

$bgNav          : $corFF;
$txtCorNav      : $cor00;
$txtCorBody     : $cor00;
$txtCorNavOver  : $cor77;
$bgNetIcon      : $corCC;
$txtCorZap      : $corFF;
$corFooter      : $corFF;
$bgFooter       : #39379d;
$bgCorZap       : #28a745;
$borderCorZap   : #1e7e34;
$bgCorTel       : #17a2b8;
$borderCorTel   : #138496;
$corPrice       : $corS01;
$bgOverChip     : $corS01;
$corFixoOver    : $corS01;
$boxBorderCor   : $cor00;
$bgOverAvatar   : $corFF;
$corOverAvatar  : #091120;
$bgBtnsGallery  : $corFF50;
$bgCorSuccess   : #28a745;
$overCorSuccess : #1e7e34;
$bgCorDanger    : #dc3545;
$overCorDanger  : #c82333;
$bgModalHeader  : $corEC;
//$bgPriceGallery  : $corFF40;


#js-app{
    .net.facebook{
        color: $corFF;
        background-color: #3b5898;
    }
    .net.instagram{
        background: #f09433;
        background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    }
    .fab {color: $corFF;}
    .form-search{
        .MuiChip-clickable{
            background-color: $corS01;
            .MuiAvatar-root{
                color: $corOverAvatar;
                background: $corFF;
            }
            .MuiChip-label{
                color: $corFF
            }
        }
    }
    .listMarks img{
        filter: grayscale(0)
    }
    .stock-title{
        display:none !important
    }
    .form-search {
        margin-top: -1.5em;
    }
    @media (max-width: 575px) {
        .searchFotterButtons{
            display: none !important;
        }
        .form-search {
            margin: 1em auto 0;
        }
    }
}



